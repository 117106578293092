import React from 'react'
import PropTypes from 'prop-types'

import Item from './Item'
import Button from '../Button'

import styles from './Items.module.scss'

const Items = ({
  allItemsPath,
  allItemsText,
  data,
  maxTwo,
  readMoreText,
  title,
}) => {
  const classNames = {
    itemsWrapper: [
      styles.itemsWrapper,
      maxTwo ? styles.maxTwo : styles.maxThree,
    ].join(' '),
  }

  return (
    <div className={styles.items}>
      {title && <h2 className="t-heading-l">{title}</h2>}

      <div className={classNames.itemsWrapper}>
        {data.edges.map(({ node }) => (
          <Item
            key={node.id}
            cover={node.frontmatter.cover}
            cover_alt={node.frontmatter.cover_alt || node.frontmatter.title}
            title={node.frontmatter.title}
            date={node.frontmatter.date}
            path={node.fields.slug}
            excerpt={node.excerpt}
            readMoreText={readMoreText}
          />
        ))}
      </div>

      {allItemsPath && (
        <Button type="link" to={allItemsPath} size="large">
          {allItemsText}
        </Button>
      )}
    </div>
  )
}

Items.propTypes = {
  allItemsPath: PropTypes.string,
  allItemsText: PropTypes.string,
  data: PropTypes.object.isRequired,
  maxTwo: PropTypes.bool,
  readMoreText: PropTypes.string,
  title: PropTypes.string,
}

Items.defaultProps = {
  allItemsText: 'Bekijk alle items',
  readMoreText: 'Lees verder',
}

export default Items
