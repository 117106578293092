import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import moment from 'moment'
import 'moment/locale/nl'
import { Link, StaticQuery, graphql } from 'gatsby'

import Button from '../Button'

import styles from './Items.module.scss'

const query = graphql`
  query {
    placeholder: file(relativePath: { eq: "assets/images/placeholder.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 400, toFormat: JPG) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Item = ({
  cover,
  cover_alt,
  date,
  excerpt,
  path,
  readMoreText,
  title,
}) => (
  <Link to={path} className={styles.item}>
    <div className={styles.imageWrapper}>
      {cover && (
        <Img
          alt={cover_alt}
          className={styles.image}
          fluid={cover.childImageSharp.fluid}
        />
      )}

      {!cover && (
        <StaticQuery
          query={query}
          render={({ placeholder }) => (
            <Img
              alt={cover_alt}
              className={styles.image}
              fluid={placeholder.childImageSharp.fluid}
            />
          )}
        />
      )}
    </div>

    <div className={styles.content}>
      <h3 className="t-heading-xs">{title}</h3>
      {date && (
        <time className={styles.date} dateTime={date}>
          {moment(date)
            .locale('nl')
            .format('DD MMMM YYYY')}
        </time>
      )}
      <p>{excerpt}</p>
      <Button color="confirm">{readMoreText}</Button>
    </div>
  </Link>
)

Item.propTypes = {
  cover: PropTypes.object,
  cover_alt: PropTypes.string,
  date: PropTypes.string,
  excerpt: PropTypes.string,
  path: PropTypes.string.isRequired,
  readMoreText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default Item
